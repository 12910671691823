import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Transportation Engineering`}</h1>
    <h2>{`Transportation engineering books`}</h2>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=11aXdj15lEWz5fc_ntJ-vMoZMaJYzYUkq"
      }}>{`Download: Highways (The location, design, construction and maintenance of pavements by Colm A.O. Flaherty 4th Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=11TMoH3KbRN9SBjKe4W9pv3Y47a-BGQlg"
      }}>{`Download: Principles of highway engineering and traffic analysis by Fred L. Mannering and Scott S. Washburn 5th Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1ZjhWpOYTg--Zmgp8blrQ9NKau2t46Ufu"
      }}>{`Download: Traffic and highway engineering by Nicholas J. Garber and Lester A. Hoel 4th Ed. `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1MYIfEqm1qkeapMFSc7JMT1XcSxxjohQU"
      }}>{`Download: Transport planning and traffic engineering by C.A.O. Flaherty `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1TwFdWVAnSreFYMOlGVqFF_QsQvxwCZaL"
      }}>{`Download: Railway engineering by Satish Chandra and M.M. Agarwal`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1zyG6hoqDOzpFFFSeTZng903yNsDqOZkq"
      }}>{`Download: Road Engineering for development by Richard Robinson and Bent Thagesen 2nd Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1ssXH0hIetNzu8c_KphXWfCM0oXvjDwhk"
      }}>{`Download: The shell bitumen handbook 5th Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1Q2NPlcRz-m8CRM9e0mUxgjMnn1k89jtN"
      }}>{`Download: The shell bitumen handbook 6th Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1Vo62Siv02GXXHspJXpjscnRQFjXYSPeg"
      }}>{`Download: Transportation Engineering, An Introduction by C. Jotin Khisty and B. Kent Lall`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1Ov257yigtr-rjmvVas1mFRAPrcXY2YeR"
      }}>{`Download: Solution manual to Transportation engineering and introduction by C. Jotin Khisty and B. Kent Lall 3rd Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1aKeov7ooB9u7MMYk1-vbpSt75koexuhx"
      }}>{`Download: Recommendations for AASHTO superelevation design 2003`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1mceEs2YDru8B4ePmsx7PDernzZ6qBGd-"
      }}>{`Download: Indian standard Methods for testing tar and bituminous materials `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1mltFEUtrfJLE3WRumQLtMHSwWXxwe59k"
      }}>{`Download: NHA General specifications`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=12utdsuKqbjyOhjEasZwAbypKz_5-g5B8"
      }}>{`Download: Petroleum fuels manufacturing handbook by Surinder Parkash`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1AIxNBzn9z0yMvQaNN4cHH3DK5c9we4n-"
      }}>{`Download: AASHTO specifications for highway construction 2008`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1QTVGhTjkJaYwrYIRu-QZ_SuQXD4n4FBa"
      }}>{`Download: Manual of uniform minimum standards for design construction and maintenance for streets and highways (Florida Green Book)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1fSWaOVfqock0xLaRwEaA-uTAjZ9y3PUp"
      }}>{`Download: CH4 Geometric Design by James H. Banks`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1sUnASs7MVyxWyw9Ypg6tUXV7_HkBwUVD"
      }}>{`Download: A policy on Geometric design of highways and streets by AASHTO 2001`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1jrtq1eM0-TADcLdllLpRJEwJ8di-3G8t"
      }}>{`Download: Asphalt mix design methods by asphalt institute 7th ed.`}</a></p>
    <h2>{`Transportation engineering lectures`}</h2>
    <h3>{`Highway Engineering`}</h3>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/Lec-1-Introduction.pdf"
      }}>{`Introduction`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/lec-2-highways-Materials.pdf"
      }}>{`Highways Materials`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/lec-3-super-elevation.pdf"
      }}>{`Super elevation`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/lec-4-stopping-side-distance.pdf"
      }}>{`Stopping sight distance`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/lec_16_Vertical_Alignment.pdf"
      }}>{`Vertical_Alignment`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/Marshall_Method_of_Mix_Design.pdf"
      }}>{`Marshall_Method_of_Mix_Design`}</a></p>
    <h3>{`Railway Engineering`}</h3>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/lec-5-introduction_to_railway.pdf"
      }}>{`Introduction_to_railway`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/lec-6-Gradient-and-gauges.pdf"
      }}>{`Gradient and gauges`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/lec-7-curves.pdf"
      }}>{`Curves`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/lec_8_formation.pdf"
      }}>{`Formation`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/lec_9_Ballast.pdf"
      }}>{`Ballast`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/lec_10_Rails.pdf"
      }}>{`Rails`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/lec_11_Points_and_Crossing.pdf"
      }}>{`Points_and_Crossing`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/lec_12_Widening_of_Gauge.pdf"
      }}>{`Widening_of_Gauge`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/lec_13-a-signalling-and-interlocking.pdf"
      }}>{`Signalling and interlocking`}</a></p>
    <h3>{`Airport Engineering`}</h3>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/lec_13-b-Runway-engineering.pdf"
      }}>{`Runway engineering`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/lec_14_payoad_and_range.pdf"
      }}>{`Payload_and_range`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/lec_15_Terminal.pdf"
      }}>{`Terminal`}</a></p>
    <h3>{`Solution to problems`}</h3>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/lecture-123.pdf"
      }}>{`lecture 1,2,3`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/lecture-3-problems-solution.pdf"
      }}>{`lecture 3 problems solution`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/lec-4-solution-of-problem.pdf"
      }}>{`lec 4 solution of problem`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/lec-81112-solution-to-class-problems-Railway-engineering.pdf"
      }}>{`lec 8,11,12 solution to class problems Railway engineering`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/lec-14-solution-to-class-problems-Runway-engineering.pdf"
      }}>{`lec 14 solution to class problems Runway engineering`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/lec-16-solution-to-class-problem.pdf"
      }}>{`lec 16 solution to class problem`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/solution-of-problem-sheet-1-Highways.pdf"
      }}>{`solution of problem sheet 1 Highways`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/solution-to-problem-sheet-2-Highways.pdf"
      }}>{`solution to problem sheet 2 Highways`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/solution-to-problem-sheet-transportation-railway-assignment.pdf"
      }}>{`solution to problem sheet transportation railway assignment`}</a></p>
    <h2>{`Transportation engineering lab experiments`}</h2>
    <h3>{`Lab experiments:`}</h3>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/0.LAYOUT-OF-TRANSPORTATION-ENGINEERING-LAB.pdf"
      }}>{`LAYOUT OF TRANSPORTATION ENGINEERING LAB`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/determination-of-flakiness-and-elongation-index-of-given-aggregate-sample/"
      }}>{`Determination of Flakiness and Elongation Index of given aggregate sample`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/determination-of-angularity-number-of-the-given-aggregate-sample/"
      }}>{`Determination of angularity number of the given aggregate sample`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/determination-of-relative-density-specific-gravity-and-water-absorption-of-different-aggregate/"
      }}>{`Determination of Relative Density (Specific Gravity) And Water Absorption of Different Aggregate`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/to-perform-los-angeles-abrasion-test-on-aggregate/"
      }}>{`To perform LOS ANGELES Abrasion test on aggregate`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/to-measure-surface-frictional-properties-using-british-pendulum-skid-resistance-tester/"
      }}>{`To Measure Surface Frictional Properties Using British Pendulum Skid Resistance Tester`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/flash-and-fire-point-test-for-asphalt-by-cleveland-open-cup-tester/"
      }}>{`Flash and Fire Point Test for Asphalt by Cleveland Open Cup Tester`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/penetration-test-on-bituminous-materials/"
      }}>{`PENETRATION TEST ON BITUMINOUS MATERIALS`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/specific-gravity-test-on-bituminous-materials/"
      }}>{`SPECIFIC GRAVITY TEST ON BITUMINOUS MATERIALS`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/determination-of-specific-viscosity-of-liquid-asphalts-using-engler-viscometer/"
      }}>{`Determination of specific viscosity of liquid asphalts using Engler Viscometer`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/standard-test-method-to-determine-softening-point-of-bitumen-ring-and-ball-apparatus/"
      }}>{`Standard test method to determine softening point of bitumen (Ring and ball apparatus)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/standard-test-method-for-ductility-of-bituminous-materials/"
      }}>{`Standard test method for ductility of bituminous materials`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/marshall-method-of-mix-design/"
      }}>{`MARSHALL METHOD OF MIX DESIGN`}</a></p>
    <h3>{`Excel sheets:`}</h3>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/Flakiness-and-elongation-index.xlsx"
      }}>{`Flakiness and elongation index`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/marshal-method-of-mix-design.xls"
      }}>{`Marshall method of mix design`}</a></p>
    <h3>{`Important notes:`}</h3>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/marshal1.pdf"
      }}>{`Marshall1`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/marshal2.pdf"
      }}>{`Marshall2`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/marshal5.pdf"
      }}>{`Marshall5`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/marshal6.pdf"
      }}>{`Marshall6`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/pavement-friction-and-skid-resistance-measurement-methds.pdf"
      }}>{`Pavement friction and skid resistance measurement methods`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      